import React from "react";
import {useParams, useNavigate} from "react-router-dom";
import BlogDetailsContent from "../../components/BlogDetailsContent";
import BlogDetailsHero from "../../components/BlogDetailsHero";
import Loader from "../../components/Loader";
import {SBlogDetails} from "./styles/SBlogDetails";
import {SBlogDetailsWrapper} from "./styles/SBlogDetailsWrapper";

const BlogDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = React.useState(null);
  React.useEffect(() => {
    if (!params.id || data) return;
    const fetchPost = async () => {
      try {
        const response = await fetch(`https://blog.busyapp.com.sa/wp-json/wp/v2/posts/${params.id}`);
        const data = await response.json();
        if (data.featured_media) {
          const mediaResponse = await fetch(
              `https://blog.busyapp.com.sa/wp-json/wp/v2/media/${data.featured_media}`
          );
          const mediaData = await mediaResponse.json();
          data.featured_image = mediaData.source_url;
        }
        if (data?.data?.status === 404) {
          navigate('/404');
        }
        setData(data);
      } catch (error) {
        navigate('/404');
      }
    };
    
    fetchPost();
  }, [params, navigate]);
  
  if (!data) return <Loader/>;
  return (
      <SBlogDetails>
        {data.featured_image && (
            <BlogDetailsHero image={data.featured_image}/>
        )}
        <SBlogDetailsWrapper>
          <BlogDetailsContent data={data}/>
          {/*<BlogDetailsSidebar/>*/}
        </SBlogDetailsWrapper>
        {/*<BlogDetailsRelatedArticles/>*/}
      </SBlogDetails>
  );
};

export default BlogDetails;
