import React from "react";
import Loader from "../../components/Loader";
import {SAboutUs} from "./styles/SAboutUs";
import {SAboutUsContainer} from "./styles/SAboutUsContainer";
import {SAboutUsContent} from "./styles/SAboutUsContent";

const AboutUs = () => {
  const [data, setData] = React.useState();
  React.useEffect(() => {
    const fetchPage = async () => {
      try {
        const response = await fetch(`https://blog.busyapp.com.sa/wp-json/wp/v2/pages/65`);
        const data = await response.json();
        
        setData(data);
      } catch (error) {
      }
    };
    
    fetchPage();
  }, []);
  if (!data?.content?.rendered) return <Loader/>;
  return (
      <SAboutUs>
        <SAboutUsContainer>
          <SAboutUsContent>
            <div dangerouslySetInnerHTML={{__html: data?.content?.rendered}}/>
          </SAboutUsContent>
        </SAboutUsContainer>
      </SAboutUs>
  );
};

export default AboutUs;
