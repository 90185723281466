import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const Small = css`
  background: #ffffff;
  border-radius: 5px 0 5px 5px;
  padding: 24px 30px;
  position: absolute;
  top: 49.87%;
  left: 2.36%;
  max-width: 342px;
  z-index: 40;
  display: none;
`;

const Medium = css`
  display: block;
`;

const Large = css`
  top: 39.87%;
  left: 12.36%;
`;

const XLarge = css``;

const XXLarge = css``;

export const SContactUsMapPopup = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
