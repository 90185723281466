import "./styles/alert.css";

const MessageAlert = ({ setMessage }) => {
  return (
    <section>
      <div
        className="bg-black"
        onClick={() => {
          setMessage(false);
        }}
      />
      <section className="alert-parent">
        <div className="bg-check">
          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
            <g clipPath="url(#clip0_330_5340)">
              <path
                d="M17.0002 2.83325C24.8241 2.83325 31.1668 9.17588 31.1668 16.9999C31.1668 24.8239 24.8241 31.1666 17.0002 31.1666C9.17613 31.1666 2.8335 24.8239 2.8335 16.9999C2.8335 9.17588 9.17613 2.83325 17.0002 2.83325ZM22.0086 11.8735L14.9964 18.8856L11.9913 15.8805C11.438 15.3273 10.5411 15.3273 9.9878 15.8805C9.43457 16.4337 9.43457 17.3307 9.9878 17.8839L13.8946 21.7907C14.5031 22.3993 15.4899 22.3993 16.0985 21.7907L24.0121 13.877C24.5653 13.3237 24.5653 12.4267 24.0121 11.8735C23.4589 11.3203 22.5619 11.3203 22.0086 11.8735Z"
                fill="#0A1730"
              />
            </g>
            <defs>
              <clipPath id="clip0_330_5340">
                <rect width="34" height="34" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <h3>تم إرسال الرسالة بنجاح !</h3>
        <p>
          أول تطبيق سعودي يتبنى نموذج تقديم الخدمات عند الطلب بين أصحاب المهارات الراغبين بالعمل وطالبي الخدمات من أفراد
          أو شركات .
        </p>
      </section>
    </section>
  );
};

export default MessageAlert;
