import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const Small = css`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #0a1730;
  margin-top: 12px;
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
  }
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SContactUsMapPopupBtn = styled.a`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
