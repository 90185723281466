import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "../../../constants/mediaquerys";

const Small = css`
  padding: 30px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  
  a {
    margin-top: auto;
  }
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SBlogsCardsCardContent = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
