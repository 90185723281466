import axios from "axios";
import React, { useEffect, useState } from "react";
import { Faq } from "../../components/CompFaqs";

const FAQ = ({ categoryId }) => {
  const [QA, setQA] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      axios
        .get("https://busy.com.co/api/faq", {
          headers: {
            Accept: "application/json",
          },
          params: {
            category_id: categoryId,
          },
        })
        .then((response) => {
          // handle success
          setQA(response.data);
          //   console.log(response.data);
        })
        .catch((error) => {
          // handle error
          console.log(error);
        });
    };
    fetchData();
  }, [categoryId]);
  return (
    <article>
      {QA?.data?.map((el, i) => {
        return <Faq title={el.question} text={el.answer} video={el.youtube_url} id={el.id} bigScreen={true} />;
      })}
    </article>
  );
};

export default FAQ;
