import React, { useEffect } from "react";
import { SAdvantagesComponent } from "./styles/SAdvantagesComponent";
import { SAdvantagesComponentCard } from "./styles/SAdvantagesComponentCard";
import { SAdvantagesComponentCardIcon } from "./styles/SAdvantagesComponentCardIcon";
import { SAdvantagesComponentCards } from "./styles/SAdvantagesComponentCards";
import { SAdvantagesComponentCardText } from "./styles/SAdvantagesComponentCardText";
import { SAdvantagesComponentCardTitle } from "./styles/SAdvantagesComponentCardTitle";
import { SAdvantagesComponentContainer } from "./styles/SAdvantagesComponentContainer";
import { SAdvantagesComponentTitleAndIcon } from "./styles/SAdvantagesComponentTitleAndIcon";
import AOS from "aos";
import "aos/dist/aos.css";
import "./styles/style.css";

const AdvantagesComponent = ({ variant, data }) => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    AOS.refresh();
  }, []);
  return (
    <SAdvantagesComponent className="advantages-component">
      <h2 className="header">مميزات التطبيق</h2>
      <SAdvantagesComponentContainer>
        <SAdvantagesComponentCards data-aos="fade-up">
          {data.map((card, index) => (
            <SAdvantagesComponentCard key={index} data-aos="fade-up">
              <SAdvantagesComponentTitleAndIcon variant={variant}>
                <SAdvantagesComponentCardIcon>{card.icon}</SAdvantagesComponentCardIcon>
                <SAdvantagesComponentCardTitle variant={variant}>{card.title}</SAdvantagesComponentCardTitle>
              </SAdvantagesComponentTitleAndIcon>
              <SAdvantagesComponentCardText style={card.style}>{card.text}</SAdvantagesComponentCardText>
            </SAdvantagesComponentCard>
          ))}
        </SAdvantagesComponentCards>
      </SAdvantagesComponentContainer>
    </SAdvantagesComponent>
  );
};

export default AdvantagesComponent;
