import { useEffect, useRef, useState } from "react";
import { SContactUsHero } from "./styles/SContactUsHero";
import { SContactUsHeroForm } from "./styles/SContactUsHeroForm";
import { SContactUsHeroFormBtn } from "./styles/SContactUsHeroFormBtn";
import { SContactUsHeroFormCont } from "./styles/SContactUsHeroFormCont";
import { SContactUsHeroFormInput } from "./styles/SContactUsHeroFormInput";
import { SContactUsHeroImg } from "./styles/SContactUsHeroImg";
import { SContactUsHeroImgCont } from "./styles/SContactUsHeroImgCont";
import { SContactUsHeroMessage } from "./styles/SContactUsHeroMessage";
import { SContactUsHeroSubTitle } from "./styles/SContactUsHeroSubTitle";
import { SContactUsHeroTitle } from "./styles/SContactUsHeroTitle";
import { SContactUsHeroWrapper } from "./styles/SContactUsHeroWrapper";
import { SSContactUsHeroContainer } from "./styles/SSContactUsHeroContainer";
import image from "../../assets/images/contact-us-hero.png";
import MessageAlert from "./MessageAlert";
import AOS from "aos";
import "aos/dist/aos.css";

const ContactUsHero = () => {
  const emailRef = useRef(null);
  const subjectRef = useRef(null);
  const messageRef = useRef(null);

  const [message, setMessage] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setMessage(true);
    // this API end point is given me an error

    fetch("https://blog.busyapp.com.sa/wp-json/wp/v2/contactus/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: emailRef.current.value,
        subject: subjectRef.current.value,
        mobile: "",
        message: messageRef.current.value,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          console.log(result);
          setMessage(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <SContactUsHero className="contact-us-hero">
      {message && <MessageAlert setMessage={setMessage} />}
      <SSContactUsHeroContainer>
        <SContactUsHeroWrapper>
          <SContactUsHeroImgCont>
            <SContactUsHeroImg data-aos="fade-left">
              <img src={image} alt="" />
            </SContactUsHeroImg>
          </SContactUsHeroImgCont>
          <SContactUsHeroFormCont data-aos="fade-right">
            <SContactUsHeroSubTitle>أتصل بنا</SContactUsHeroSubTitle>
            <SContactUsHeroTitle>خليك علي تواصل معنا</SContactUsHeroTitle>
            <SContactUsHeroMessage>{message}</SContactUsHeroMessage>
            <SContactUsHeroForm onSubmit={onSubmit}>
              <SContactUsHeroFormInput type="email" ref={emailRef} placeholder="البريد الإلكتروني" required />
              <SContactUsHeroFormInput type="text" ref={subjectRef} placeholder="الموضوع" required />
              <SContactUsHeroFormInput type="text" ref={messageRef} placeholder="الرسالة" required />
              <SContactUsHeroFormBtn onClick={onsubmit}>
                إرسال الرسالة
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.90918 0.862305L6.35059 0.303711C6.09668 0.0751953 5.71582 0.0751953 5.4873 0.303711L0.536133 5.22949C0.307617 5.4834 0.307617 5.86426 0.536133 6.09277L5.4873 11.0439C5.71582 11.2725 6.09668 11.2725 6.35059 11.0439L6.90918 10.4854C7.1377 10.2314 7.1377 9.85059 6.90918 9.59668L3.83691 6.67676H11.124C11.4795 6.67676 11.7334 6.42285 11.7334 6.06738V5.25488C11.7334 4.9248 11.4795 4.64551 11.124 4.64551H3.83691L6.90918 1.75098C7.1377 1.49707 7.16309 1.11621 6.90918 0.862305Z"
                    fill="white"
                  />
                </svg>
              </SContactUsHeroFormBtn>
            </SContactUsHeroForm>
          </SContactUsHeroFormCont>
        </SContactUsHeroWrapper>
      </SSContactUsHeroContainer>
    </SContactUsHero>
  );
};

export default ContactUsHero;
