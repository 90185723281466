import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "../../../constants/mediaquerys";

const Small = css`
  background: none;
  border: 0;
  outline: 0;
  padding: 8px;
  cursor: pointer;
  transition: opacity 300ms;
  
  &:disabled {
    opacity: 0.2;
    cursor: unset;
  }
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SHeroNavigationButton = styled.button`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
