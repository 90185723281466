import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "../../../constants/mediaquerys";

const Small = css`
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  
  
  a:last-of-type {
    margin-top: 16px;
  }
`;

const Medium = css`
  flex-direction: row;
  
  a {
    width: unset;
  }
  
  a:last-of-type {
    margin-top: 0;
    margin-right: 9px;
  }
`;

const Large = css`

`;

const XLarge = css``;

const XXLarge = css``;

export const SHeroButtons = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
