import { Link } from "react-router-dom";
import { SBlogsCards } from "./styles/SBlogsCards";
import { SBlogsCardsCard } from "./styles/SBlogsCardsCard";
import { SBlogsCardsCardContent } from "./styles/SBlogsCardsCardContent";
import { SBlogsCardsCardImg } from "./styles/SBlogsCardsCardImg";
import { SBlogsCardsCardLink } from "./styles/SBlogsCardsCardLink";
import { SBlogsCardsCardText } from "./styles/SBlogsCardsCardText";
import { SBlogsCardsCardTitle } from "./styles/SBlogsCardsCardTitle";
import { SBlogsCardsContainer } from "./styles/SBlogsCardsContainer";
import { SBlogsCardsWrapper } from "./styles/SBlogsCardsWrapper";

const BlogsCards = ({ posts }) => {
  return (
    <SBlogsCards className="blogs-cards">
      <SBlogsCardsContainer>
        <SBlogsCardsWrapper>
          {posts.map((post) => (
            <SBlogsCardsCard key={post.id}>
              <SBlogsCardsCardImg>
                <img src={post.featured_image} alt={post.title.rendered} />
              </SBlogsCardsCardImg>
              <SBlogsCardsCardContent>
                <SBlogsCardsCardTitle>{post.title.rendered}</SBlogsCardsCardTitle>
                {/*<SBlogsCardsCardAuthor>*/}
                {/*  بواسطة إيهاب حافظ بتاريخ 15 / 5 / 2023*/}
                {/*</SBlogsCardsCardAuthor>*/}
                <SBlogsCardsCardText>
                  <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                </SBlogsCardsCardText>
                <Link to={`/blogs/${post.id}`}>
                  <SBlogsCardsCardLink>
                    أقرأ المزيد
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.5 15L7.5 10L12.5 5"
                        stroke="#0A1730"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </SBlogsCardsCardLink>
                </Link>
              </SBlogsCardsCardContent>
            </SBlogsCardsCard>
          ))}
        </SBlogsCardsWrapper>
      </SBlogsCardsContainer>
    </SBlogsCards>
  );
};

export default BlogsCards;
