import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const CSSPrimaryButton = css`
  background: #0a1730;
  color: #e5f97f;
  min-width: 180px;
`;

const CSSSecondaryButton = css`
  border: 2px solid #0a1730;
  color: #101010;
  min-width: 180px;
`;

const CSSTextIconButton = css`
  background: #0a1730;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  border: 0;
  color: #e5f97f;
  border: 0;

  svg {
    margin-right: 16px;
  }
`;

const Small = css`
  border-radius: 7px;
  display: block;
  cursor: pointer;
  padding: 20px 44px;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  width: fit-content;
  border: 0;
  outline: 0;
  border-image-width: 0;
  text-align: center;
  text-decoration: none;
  ${({ btnType }) => btnType === "primary" && CSSPrimaryButton};
  ${({ btnType }) => btnType === "secondary" && CSSSecondaryButton};
  ${({ btnType }) => btnType === "with-icon" && CSSTextIconButton};
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SButton = styled.a`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
