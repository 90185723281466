import { Link } from "react-router-dom";
import { SBlogsHero } from "./styles/SBlogsHero";
import { SBlogsHeroContainer } from "./styles/SBlogsHeroContainer";
import { SBlogsHeroContent } from "./styles/SBlogsHeroContent";
import { SBlogsHeroImg } from "./styles/SBlogsHeroImg";
import { SBlogsHeroImgCont } from "./styles/SBlogsHeroImgCont";
import { SBlogsHeroLink } from "./styles/SBlogsHeroLink";
import { SBlogsHeroText } from "./styles/SBlogsHeroText";
import { SBlogHeroTitle } from "./styles/SBlogsHeroTitle";
import { SBlogsHeroWrapper } from "./styles/SBlogsHeroWrapper";

const BlogsHero = ({ post }) => {
  return (
    <SBlogsHero className="blogs-hero">
      <SBlogsHeroContainer>
        <SBlogsHeroWrapper>
          <SBlogsHeroImgCont>
            <SBlogsHeroImg>
              <img src={post.featured_image} alt={post.title.rendered} />
            </SBlogsHeroImg>
          </SBlogsHeroImgCont>
          <SBlogsHeroContent>
            <SBlogHeroTitle>{post.title.rendered}</SBlogHeroTitle>
            {/*<SBlogsHeroAuthor>*/}
            {/*  بواسطة إيهاب حافظ بتاريخ 15 / 5 / 2023*/}
            {/*</SBlogsHeroAuthor>*/}
            <SBlogsHeroText>
              <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
            </SBlogsHeroText>
            <Link to={`/blogs/${post.id}`}>
              <SBlogsHeroLink href="">
                أقرأ المزيد
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.5 15L7.5 10L12.5 5"
                    stroke="#0A1730"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SBlogsHeroLink>
            </Link>
          </SBlogsHeroContent>
        </SBlogsHeroWrapper>
      </SBlogsHeroContainer>
    </SBlogsHero>
  );
};

export default BlogsHero;
