import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const Small = css`
  background: #f9f9f9;
  border: 1px solid #0a1730;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SCompStepsStepNum = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
