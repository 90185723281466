import { SLoader } from "./styles/SLoader";
import { SLoaderContainer } from "./styles/SLoaderContainer";
import { SLoaderWrapper } from "./styles/SLoaderWrapper";

const Loader = () => {
  return (
    <SLoader>
      <SLoaderContainer>
        <SLoaderWrapper>
          <svg width="262" height="65" viewBox="0 0 262 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M41.6006 26.4879H11.2684V13.4447H41.6006C46.5259 13.4447 48.5644 13.4447 48.5644 20.0085C48.5644 26.4879 46.5259 26.4879 41.6006 26.4879ZM11.2684 37.9189H43.0833C47.8193 37.9189 49.9809 37.9189 49.9809 44.7349C49.9809 51.5503 47.8193 51.5503 43.0833 51.5503H11.2684V37.9189ZM55.6325 31.3584C58.6171 27.9345 60.2449 23.2189 60.2449 17.9066C60.2449 7.59531 54.5546 1.68143 44.6337 1.68143H0.127686V63.3157H44.9696C55.776 63.3157 61.7275 56.9551 61.7275 45.4068C61.7275 38.0286 58.6456 33.7645 55.6325 31.3584Z"
              fill="#0A1730"
            />
            <path
              d="M117.644 33.1292C117.644 42.904 115.287 52.8965 97.8062 52.8965C80.4296 52.8965 78.0341 43.3969 78.0341 33.1292V1.68261H66.8939V33.2975C66.8939 54.9264 76.716 65 97.8062 65C118.894 65 128.716 54.9264 128.716 33.2975V1.68261H117.644V33.1292Z"
              fill="#0A1730"
            />
            <path
              d="M165.107 26.159C148.605 24.7452 147.048 23.0276 147.048 18.8335C147.048 15.1086 148.277 11.7638 163.111 11.7638C179.128 11.7638 182.411 15.5317 182.411 21.1886V22.4454H194.164L194.157 21.1816C194.09 7.52338 183.471 0 164.257 0C151.826 0 135.3 1.90033 135.3 18.3292C135.3 33.0201 146.032 36.6665 164.566 38.0002C182.841 39.3882 184.433 40.4758 184.433 45.4086C184.433 49.0517 182.415 53.233 166.684 53.233C147.777 53.233 145.227 48.0034 145.227 42.8857V41.6278H133.358L133.414 42.9384C133.818 52.4094 137.713 65 166.75 65C179.384 65 196.18 62.9078 196.18 44.8194C196.18 28.8577 183.147 27.7244 165.107 26.159Z"
              fill="#0A1730"
            />
            <path d="M247.482 1.52873L208.432 63.1802H222.739L261.788 1.52873H247.482Z" fill="#0A1730" />
            <path d="M227.31 33.3718L208.337 1.68814H194.075L213.049 33.3718H227.31Z" fill="#0A1730" />
          </svg>
          <svg width="173" height="9" viewBox="0 0 173 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="173" height="9" rx="4.5" fill="#EAEAEA" />
            <rect width="74" height="9" rx="4.5" fill="#E5F97F" />
          </svg>
        </SLoaderWrapper>
      </SLoaderContainer>
    </SLoader>
  );
};

export default Loader;
