import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "../../../constants/mediaquerys";

const Small = css`
  background: #000000;
  padding: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.01em;
  text-align: center;
  color: #FFFFFF;
  margin-top: 50px;
`;

const Medium = css``;

const Large = css``;

const XLarge = css`
  margin-top: 200px;
`;

const XXLarge = css``;

export const SCompFooterCopyrights = styled.p`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
