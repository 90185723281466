import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import { SCompSteps } from "./styles/SCompSteps";
import { SCompStepsButton } from "./styles/SCompStepsButton";
import { SCompStepsContainer } from "./styles/SCompStepsContainer";
import { SCompStepsLine } from "./styles/SCompStepsLine";
import { SCompStepsStep } from "./styles/SCompStepsStep";
import { SCompStepsStepNum } from "./styles/SCompStepsStepNum";
import { SCompStepsStepText } from "./styles/SCompStepsStepText";
import { SCompStepsStepTitle } from "./styles/SCompStepsStepTitle";
import { SCompStepsTitle } from "./styles/SCompStepsTitle";
import { SCompStepsWrapper } from "./styles/SCompStepsWrapper";

const CompSteps = () => {
  return (
    <SCompSteps className="comp-steps">
      <SCompStepsContainer>
        <SCompStepsTitle>خطوات الحصول علي الخدمة</SCompStepsTitle>
        <SCompStepsWrapper>
          <SCompStepsLine>
            <svg xmlns="http://www.w3.org/2000/svg" width="799" height="59" viewBox="0 0 799 59" fill="none">
              <path
                d="M798 29.5C798 29.5 587.299 -34.6249 399.5 29.5C211.701 93.6249 0.999992 29.5 0.999992 29.5"
                stroke="#0A1730"
                stroke-width="2"
                stroke-dasharray="4 4"
              />
            </svg>
          </SCompStepsLine>
          <SCompStepsStep>
            <SCompStepsStepNum>
              <svg xmlns="http://www.w3.org/2000/svg" width="97" height="97" viewBox="0 0 97 97" fill="none">
                <path
                  d="M48.5 0.5C21.9903 0.5 0.5 21.9903 0.5 48.5C0.5 75.0097 21.9903 96.5 48.5 96.5C75.0097 96.5 96.5 75.0097 96.5 48.5C96.5 21.9903 75.0097 0.5 48.5 0.5Z"
                  fill="#F9F9F9"
                  stroke="#0A1730"
                />
                <path d="M47 33H51V36V61H47V33Z" fill="#0A1730" />
              </svg>
            </SCompStepsStepNum>
            <SCompStepsStepTitle>أعرض الخدمة</SCompStepsStepTitle>
            <SCompStepsStepText>
              اعرض خدماتك أو مهاراتك التي يمكنك القيام بها ، مع شرح تفصيلي للخدمة و وضع نماذج لسابقة أعمالك.
            </SCompStepsStepText>
          </SCompStepsStep>
          <SCompStepsStep>
            <SCompStepsStepNum>
              <svg xmlns="http://www.w3.org/2000/svg" width="97" height="97" viewBox="0 0 97 97" fill="none">
                <rect x="0.5" y="0.5" width="96" height="96" rx="48" fill="#F9F9F9" stroke="#0A1730" />
                <path d="M42 33H56V36.0307H45.4157V61H42V33Z" fill="#0A1730" />
              </svg>
            </SCompStepsStepNum>
            <SCompStepsStepTitle>حدد السعر</SCompStepsStepTitle>
            <SCompStepsStepText>
              قُم بتحديد السعر المناسب لخدماتك أو مهاراتك مع تحديد طريقة العمل بالساعة أو بالمهمة.{" "}
            </SCompStepsStepText>
          </SCompStepsStep>
          <SCompStepsStep>
            <SCompStepsStepNum>
              <svg xmlns="http://www.w3.org/2000/svg" width="97" height="97" viewBox="0 0 97 97" fill="none">
                <rect x="0.5" y="0.5" width="96" height="96" rx="48" fill="#F9F9F9" stroke="#0A1730" />
                <path
                  d="M56.9323 38.0212V46.3699C56.9323 52.3446 52.9359 53.7295 49.6518 53.7295C47.5152 53.7295 45.4972 53.1756 43.7167 52.1864V62.5135H40.5117V38.0212H43.7167V49.7332C44.9829 50.5641 46.8425 51.1576 48.5835 51.1576C51.9863 51.1576 53.7273 49.2584 53.7273 45.8951V39.2082L56.9323 38.0212Z"
                  fill="#0A1730"
                />
              </svg>
            </SCompStepsStepNum>
            <SCompStepsStepTitle>تلقي العروض</SCompStepsStepTitle>
            <SCompStepsStepText>
              ابدأ في تلقي العروض من طالبي الخدمات وبإمكانك التواصل معهم لمعرفة أي تفاصيل أو معلومات تخص الخدمة.{" "}
            </SCompStepsStepText>
          </SCompStepsStep>
        </SCompStepsWrapper>
        <SCompStepsButton>
          <Link to="/download-app">
            <Button btnType="primary" as="span">
              حمل التطبيق
            </Button>
          </Link>
        </SCompStepsButton>
      </SCompStepsContainer>
    </SCompSteps>
  );
};

export default CompSteps;
