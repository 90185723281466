import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const Small = css`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #0a1730;
  margin-top: 30px;

  svg {
    margin-right: 8px;
  }
`;

const Medium = css``;

const Large = css``;

const XLarge = css`
  margin-top: 80px;
`;

const XXLarge = css``;

export const SBlogsHeroLink = styled.span`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
