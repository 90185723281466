import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "../../../constants/mediaquerys";

const Small = css`
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
`;

const Medium = css``;

const Large = css`
  grid-template-columns: repeat(2, 1fr);
`;

const XLarge = css`
  display: flex;
  gap: 0;
`;

const XXLarge = css``;

export const SCompArticlesCards = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
