import React from "react";
import CompDownloadApp from "../../components/CompDownloadApp";
import {SDownloadApp} from "./styles/SDownloadApp";

const DownloadApp = () => {
  return (
      <SDownloadApp>
        <CompDownloadApp/>
      </SDownloadApp>
  );
};

export default DownloadApp;
