import React from "react";
import AdvantagesComponent from "../../components/AdvantagesComponent";
import ContactUsHero from "../../components/ContactUsHero";
import { contactUsAdvantagesComponent } from "../../constants/dummyData";
import { SContactUs } from "./styles/SContactUs";
import ContactUsMap from "../../components/ContactUsMap";

const ContactUs = () => {
  return (
    <SContactUs>
      <ContactUsHero />
      <AdvantagesComponent {...contactUsAdvantagesComponent} />
      <ContactUsMap />
    </SContactUs>
  );
};

export default ContactUs;
