import {createGlobalStyle} from "styled-components";

export const GStyleBase = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  html {
    direction: rtl;
  }
  
  :root {
    margin: 0;
    font-family: 'Tajawal', sans-serif;
    font-size: 16px;
  }
  
  a {
    text-decoration: none;
    display: block;
  }
`;
