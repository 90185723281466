import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const Small = css`
  border-bottom: 1px solid #d3d3d3;
  font-weight: 500;
  font-size: 23px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #000000;
  padding-bottom: 6px;
  cursor: pointer;
  width: fit-content;

  svg {
    transition: transform 300ms;
    margin-right: 12px;
    transform: scale(${({ isOpened }) => (isOpened ? "-1" : "1")});
  }
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SCompFaqsFaqTitle = styled.h3`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
