import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const Small = css`
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  padding: 40px 32px 0;
`;

const Medium = css``;

const Large = css`
  padding: 60px 90px 0;
`;

const XLarge = css`
  padding: 60px 130px 0;
`;

const XXLarge = css``;

export const SCompFooterContainer = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
