import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const Small = css`
  background: #ffffff;
  border-radius: 4px;
  padding: 4px 6px;
  display: flex;

  input {
    background: transparent;
    border: 0;
    outline: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.01em;
    color: #808080;
    padding: 12px 14px 12px;
    flex: 1 1 auto;
  }

  button {
    background: #0a1730;
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #ffffff;
    padding: 16px 24px;
    outline: 0;
    border: 0;
    cursor: pointer;
  }
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SCompFooterContactFormInputCont = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
