import {SBlogDetailsHero} from "./styles/SBlogDetailsHero";
import {SBlogDetailsHeroContainer} from "./styles/SBlogDetailsHeroContainer";
import {SBlogDetailsHeroImg} from "./styles/SBlogDetailsHeroImg";

const BlogDetailsHero = ({image}) => {
  return (
      <SBlogDetailsHero className="blog-details-hero">
        <SBlogDetailsHeroContainer>
          <SBlogDetailsHeroImg>
            <img src={image} alt=""/>
          </SBlogDetailsHeroImg>
          {/*<SBlogDetailsHeroAuthor>*/}
          {/*  بواسطة إيهاب حافظ بتاريخ 15 / 5 / 2023*/}
          {/*</SBlogDetailsHeroAuthor>*/}
        </SBlogDetailsHeroContainer>
      </SBlogDetailsHero>
  )
}

export default BlogDetailsHero;