import React from "react";
import AdvantagesComponent from "../../components/AdvantagesComponent";
import CompArticles from "../../components/CompArticles";
import CompFaqs from "../../components/CompFaqs";
import CompSteps from "../../components/CompSteps";
import Hero from "../../components/Hero";
import { homeAdvantagesComponent } from "../../constants/dummyData";
import { SHome } from "./styles/SHome";
import About from "../../components/About";

const Home = () => {
  return (
    <SHome>
      <Hero />
      <About />
      <AdvantagesComponent {...homeAdvantagesComponent} />
      <CompSteps />
      <CompFaqs />
    </SHome>
  );
};

export default Home;
