import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const Small = css`
  font-family: "Tajawal";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.01em;
  color: #000000;
  display: flex;
  align-items: center;
  margin-bottom: 28px;
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SCompDownloadAppListItem = styled.li`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
