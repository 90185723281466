import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const Small = css`
  background: #ffffff;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.05);
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  padding: 20px;
  z-index: 2;

  &:before {
    content: "";
    width: 65%;
    position: absolute;
    top: 0;
    right: 0;
    height: 4px;
    background-color: #0a1730;
  }
`;

const Medium = css``;

const Large = css`
  padding: 29px 68px 20px;
`;

const XLarge = css``;

const XXLarge = css``;

export const SHeaderContainer = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
