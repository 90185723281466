import React from "react";
import {SButton} from "./styles/SButton";

const Button = ({btnType, children, ...props}) => {
  return (
      <SButton className="button" {...props} btnType={btnType || "primary"}>
        {children}
      </SButton>
  );
};

export default Button;
