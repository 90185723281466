import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "../../../constants/mediaquerys";

const Small = css`
  display: flex;
  flex-direction: column;
  background: #F9F9F9;
  border-radius: 8px;
  overflow: hidden;
`;

const Medium = css``;

const Large = css`
`;

const XLarge = css`
  flex-direction: ${({isFullHeight}) => isFullHeight ? "column" : "row"};
`;

const XXLarge = css``;

export const SCompArticlesCard = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
