import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "../../../constants/mediaquerys";

const Small = css`
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  padding: 0 32px 24px;
`;

const Medium = css``;

const Large = css`
  padding: 0 120px 24px;
  display: flex;
`;

const XLarge = css`
  padding: 0 140px 24px;
`;


const XXLarge = css``;

export const SBlogDetailsWrapper = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
