import { useRef, useState } from "react";
import AppleStoreIcon from "../../icons/AppleStoreIcon";
import GooglePlayIcon from "../../icons/GooglePlayIcon";
import { SCompFooter } from "./styles/SCompFooter";
import { SCompFooterContact } from "./styles/SCompFooterContact";
import { SCompFooterContactForm } from "./styles/SCompFooterContactForm";
import { SCompFooterContactFormInputCont } from "./styles/SCompFooterContactFormInputCont";
import { SCompFooterContactLabel } from "./styles/SCompFooterContactLabel";
import { SCompFooterContainer } from "./styles/SCompFooterContainer";
import { SCompFooterCopyrights } from "./styles/SCompFooterCopyrights";
import { SCompFooterLink } from "./styles/SCompFooterLink";
import { SCompFooterLinks } from "./styles/SCompFooterLinks";
import { SCompFooterLinksCont } from "./styles/SCompFooterLinksCont";
import { SCompFooterLinksTitle } from "./styles/SCompFooterLinksTitle";
import { SCompFooterSocial } from "./styles/SCompFooterSocial";
import { SCompFooterSocialBtn } from "./styles/SCompFooterSocialBtn";
import { SCompFooterWrapper } from "./styles/SCompFooterWrapper";
import { Link } from "react-router-dom";

const Footer = () => {
  const inputRef = useRef(null);
  const [message, setMessage] = useState("");
  const onSubmit = (e) => {
    e.preventDefault();
    fetch("https://blog.busyapp.com.sa/wp-json/wp/v2/newsletter/save", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: inputRef.current.value,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          setMessage("تم الإشتراك بنجاح");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <SCompFooter className="footer">
      <SCompFooterContainer>
        <SCompFooterWrapper>
          <SCompFooterLinksCont>
            <SCompFooterLinks>
              <SCompFooterLinksTitle>عن التطبيق</SCompFooterLinksTitle>
              <SCompFooterLink as="a" href={"/about-us"}>
                من نحن
              </SCompFooterLink>

              <SCompFooterLink as="a" href={"/privacy-policy"}>
                سياسة الخصوصية
              </SCompFooterLink>
              <SCompFooterLink as="a" href={"/contact-us"}>
                تواصل معنا
              </SCompFooterLink>
            </SCompFooterLinks>
            <SCompFooterLinks>
              <SCompFooterLinksTitle>المساعدة</SCompFooterLinksTitle>
              <SCompFooterLink>أتصل بنا</SCompFooterLink>
              <SCompFooterLink as="a" href={"tel:+966138113815"} style={{ textAlign: "left", direction: "ltr" }}>
                +966138113815
              </SCompFooterLink>
              <SCompFooterLink>البريد الإلكتروني</SCompFooterLink>
              <SCompFooterLink as="a" href={"mailto:info@busyapp.com.sa"}>
                info@busyapp.com.sa
              </SCompFooterLink>
            </SCompFooterLinks>
            <SCompFooterLinks>
              <SCompFooterLinksTitle>حمل التطبيق</SCompFooterLinksTitle>
              <SCompFooterLink as="a" href="https://apps.apple.com/us/app/busy/id6444770742">
                <AppleStoreIcon />
              </SCompFooterLink>
              <SCompFooterLink as="a" href="https://play.google.com/store/apps/details?id=com.details.busy">
                <GooglePlayIcon />
              </SCompFooterLink>
            </SCompFooterLinks>
          </SCompFooterLinksCont>
          <SCompFooterContact>
            {message || (
              <SCompFooterContactForm onSubmit={onSubmit}>
                <SCompFooterContactLabel htmlFor="email">خليك دايما علي تواصل</SCompFooterContactLabel>
                <SCompFooterContactFormInputCont>
                  <input type="email" id="email" name="email" ref={inputRef} placeholder="البريد الإلكتروني" required />
                  <button style={{ backgroundColor: "#0A1730", fontFamily: "Tajawal" }}>أشترك الأن</button>
                </SCompFooterContactFormInputCont>
              </SCompFooterContactForm>
            )}
            <SCompFooterSocial>
              <SCompFooterSocialBtn href="">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 12L0.848137 8.92015C0.323782 8.01616 0.0487106 6.9924 0.0515759 5.94582C0.0515759 2.66635 2.73352 0 6.02578 0C7.62464 0 9.12607 0.618821 10.2521 1.7424C11.3811 2.86597 12.0029 4.36027 12 5.94867C12 9.22814 9.31804 11.8945 6.02292 11.8945H6.02005C5.02005 11.8945 4.03725 11.6435 3.16332 11.1702L0 12ZM4.19751 3.20207C4.30353 3.20492 4.42101 3.21063 4.53276 3.45872C4.60436 3.61829 4.72331 3.90828 4.82301 4.15135C4.90684 4.35573 4.97706 4.52692 4.99407 4.55948C5.03132 4.63363 5.05425 4.71918 5.00554 4.81899C4.99978 4.83078 4.99435 4.84205 4.98912 4.85291C4.95005 4.93394 4.92224 4.99164 4.85654 5.06709C4.82965 5.09797 4.80164 5.13182 4.77372 5.16556C4.7243 5.2253 4.67516 5.28468 4.63304 5.3266C4.55854 5.40074 4.48118 5.48059 4.56714 5.62888C4.6531 5.77717 4.95396 6.26481 5.39809 6.65835C5.87555 7.08339 6.29073 7.2625 6.50063 7.35305C6.5414 7.37063 6.57443 7.38488 6.59866 7.39694C6.74765 7.47108 6.83648 7.45968 6.92244 7.35987C7.0084 7.26006 7.29493 6.92641 7.39522 6.77812C7.49264 6.62983 7.59293 6.65549 7.73046 6.70397C7.868 6.7553 8.60152 7.11462 8.75052 7.18876C8.77988 7.20337 8.80735 7.21654 8.83282 7.22876C8.93659 7.27851 9.00714 7.31234 9.03705 7.36272C9.0743 7.42546 9.0743 7.72203 8.95109 8.06994C8.82502 8.41785 8.2147 8.7515 7.9425 8.77717C7.91646 8.77962 7.89055 8.78275 7.86388 8.78598C7.61177 8.81647 7.29181 8.85517 6.15167 8.40644C4.74699 7.85412 3.8197 6.48381 3.63204 6.20649C3.6172 6.18456 3.60699 6.16946 3.60153 6.16215L3.59836 6.1579C3.51305 6.04377 2.99121 5.34553 2.99121 4.62507C2.99121 3.93586 3.33077 3.57702 3.48562 3.41337C3.49543 3.403 3.5045 3.39342 3.5127 3.38458C3.65024 3.23629 3.81069 3.19922 3.91098 3.19922C4.01127 3.19922 4.11155 3.19922 4.19751 3.20207Z"
                    fill="currentColor"
                  />
                </svg>
              </SCompFooterSocialBtn>
              <SCompFooterSocialBtn href="">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path
                    d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
                    fill="currentColor"
                  />
                </svg>
              </SCompFooterSocialBtn>
              <SCompFooterSocialBtn href="">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.5286 3.59033C10.5332 3.69593 10.5332 3.79673 10.5332 3.90233C10.5378 7.10394 8.18559 10.8 3.88396 10.8C2.61524 10.8 1.36967 10.4207 0.300049 9.71035C0.485264 9.73435 0.670479 9.74395 0.855695 9.74395C1.90679 9.74395 2.93011 9.37915 3.75894 8.70234C2.75878 8.68314 1.87901 8.00634 1.5734 7.01754C1.92531 7.08954 2.28648 7.07514 2.62913 6.97434C1.54099 6.75354 0.758457 5.75993 0.753826 4.60313C0.753826 4.59353 0.753826 4.58393 0.753826 4.57433C1.07795 4.76153 1.44375 4.86713 1.81418 4.87673C0.790869 4.16633 0.471373 2.75033 1.09184 1.64152C2.28185 3.15833 4.03214 4.07513 5.91207 4.17593C5.72223 3.33593 5.98153 2.45272 6.58811 1.85752C7.52807 0.94072 9.0098 0.98872 9.89883 1.96312C10.4221 1.85752 10.9268 1.65592 11.3852 1.37272C11.2092 1.93432 10.8434 2.40952 10.3572 2.71193C10.8203 2.65432 11.2741 2.52472 11.7 2.33272C11.3852 2.82233 10.987 3.24473 10.5286 3.59033Z"
                    fill="currentColor"
                  />
                </svg>
              </SCompFooterSocialBtn>
              <SCompFooterSocialBtn href="">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.60685 6.66413L8.91781 4.61655H6.9726V3.2884C6.9726 2.72808 7.24384 2.1816 8.11507 2.1816H9V0.438398C9 0.438398 8.19726 0.300049 7.43014 0.300049C5.8274 0.300049 4.78082 1.28095 4.78082 3.05597V4.61655H3V6.66413H4.78082V11.6143C5.13836 11.671 5.50411 11.7 5.87671 11.7C6.24932 11.7 6.61507 11.671 6.9726 11.6143V6.66413H8.60685Z"
                    fill="currentColor"
                  />
                </svg>
              </SCompFooterSocialBtn>
            </SCompFooterSocial>
          </SCompFooterContact>
        </SCompFooterWrapper>
      </SCompFooterContainer>
      <SCompFooterCopyrights>
        جميع الحقوق محفوظة 2023 © شركة تفاصيل الأفكار
        <span style={{ paddingRight: "8px" }}>All rights reserved 2023 © Details Digital SA</span>
      </SCompFooterCopyrights>
    </SCompFooter>
  );
};

export default Footer;
