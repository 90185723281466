import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import axios from "axios";
import "./styles/privacy.css";
import FAQ from "./FAQ";

const PrivacyPolicy = () => {
  let [active, setActive] = useState(2);
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const fetchPage = async () => {
      axios
        .get("https://busy.com.co/api/faq-categories", {
          headers: {
            Accept: "application/json",
          },
        })
        .then((response) => {
          // handle success
          setCategories(response.data);
        })
        .catch((error) => {
          // handle error
          console.log(error);
        });
    };
    fetchPage();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  if (categories?.status !== true) return <Loader />;
  return (
    <main className="faq-page">
      <section className="pagination">
        {categories?.data.faq_categories?.map((cat) => {
          // making a new arr from category name
          let arr = cat.name.split(" ");
          // remove the last character from the arr
          arr.pop();
          // turn the new arr into a string and print it
          let newName = arr.join(" ");
          return (
            <>
              <button
                key={cat.id}
                className={`${active === cat.id ? "active-faq" : null} faq-button`}
                onClick={() => setActive(cat.id)}
              >
                {newName}
              </button>
            </>
          );
        })}
      </section>
      <FAQ categoryId={active} />
    </main>
  );
};

export default PrivacyPolicy;
