import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "../../../constants/mediaquerys";

const Small = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #202020;
  padding-bottom: 8px;
  border-bottom: 1px solid #202020;
  text-decoration: none;
  width: fit-content;
  margin-top: 24px;
  display: block;
`;

const Medium = css``;

const Large = css`
  margin-top: 0;
`;

const XLarge = css``;

const XXLarge = css``;

export const SCompArticlesBtn = styled.span`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
