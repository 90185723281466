import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { SCompFaqsFaq } from "../../components/CompFaqs/styles/SCompFaqsFaq";
import { SCompFaqsFaqTitle } from "../../components/CompFaqs/styles/SCompFaqsFaqTitle";
import { SCompFaqsFaqTextCont } from "../../components/CompFaqs/styles/SCompFaqsFaqTextCont";
import { SCompFaqsFaqText } from "../../components/CompFaqs/styles/SCompFaqsFaqText";
import "../../components/CompFaqs/styles/bigScreenFaq.css";
import Loader from "../../components/Loader";

const FaqDetails = () => {
  const { FaqId } = useParams();
  const [QA, setQA] = useState([]);
  const [status, setState] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      axios
        .get("https://busy.com.co/api/faq", {
          headers: {
            Accept: "application/json",
          },
        })
        .then((response) => {
          // handle success
          setState(response.data.status);
          const singleFaq = response.data.data.filter((faq) => faq.id === Number(FaqId));
          setQA(singleFaq[0]);
        })
        .catch((error) => {
          // handle error
          console.log(error);
        });
    };
    fetchData();
  }, [FaqId]);
  const [active, setActive] = useState(true);
  const ref = useRef(null);

  if (status !== true) return <Loader />;

  return (
    <SCompFaqsFaq className="comp-faqs" style={{ margin: "100px 10% 50px", height: active ? "auto" : "60vh" }}>
      <SCompFaqsFaqTitle
        isOpened={active}
        onClick={() => {
          setActive(!active);
        }}
        className={`q-parent`}
      >
        {QA.question}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="14"
          viewBox="0 0 23 14"
          fill="none"
          style={{ marginRight: "34px" }}
        >
          <path
            d="M10.4393 13.0607C11.0251 13.6464 11.9749 13.6464 12.5607 13.0607L22.1066 3.51472C22.6924 2.92893 22.6924 1.97918 22.1066 1.3934C21.5208 0.807612 20.5711 0.807612 19.9853 1.3934L11.5 9.87868L3.01472 1.3934C2.42893 0.807612 1.47918 0.807612 0.893398 1.3934C0.307612 1.97918 0.307612 2.92893 0.893398 3.51472L10.4393 13.0607ZM10 11V12L13 12V11L10 11Z"
            fill="black"
          />
        </svg>
      </SCompFaqsFaqTitle>

      <SCompFaqsFaqTextCont ref={ref} isOpened={active} heightValue={ref?.current?.scrollHeight}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <iframe src={QA.youtube_url} title="Busy" width="auto" height="400px"></iframe>
        </div>
        <SCompFaqsFaqText className={`answer`} dangerouslySetInnerHTML={{ __html: QA.answer }}></SCompFaqsFaqText>
      </SCompFaqsFaqTextCont>
    </SCompFaqsFaq>
  );
};

export default FaqDetails;
