import React, {useEffect, useState} from "react";
import BlogsHero from "../../components/BlogHero";
import BlogsCards from "../../components/BlogsCards";
import Loader from "../../components/Loader";
import {SBlogs} from "./styles/SBlogs";

const Blogs = () => {
  const [posts, setPosts] = useState([]);
  
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch('https://blog.busyapp.com.sa/wp-json/wp/v2/posts');
        const data = await response.json();
        
        // Fetch featured images separately
        const postsWithImages = await Promise.all(
            data.map(async (post) => {
              if (post.featured_media) {
                const mediaResponse = await fetch(
                    `https://blog.busyapp.com.sa/wp-json/wp/v2/media/${post.featured_media}`
                );
                const mediaData = await mediaResponse.json();
                return {
                  ...post,
                  featured_image: mediaData.source_url,
                };
              }
              return post;
            })
        );
        
        setPosts(postsWithImages);
      } catch (error) {
        console.error(error);
      }
    };
    
    fetchPosts();
  }, []);
  if (!posts?.length) return <Loader/>;
  return (
      <SBlogs>
        <BlogsHero post={posts[1]}/>
        <BlogsCards posts={posts}/>
      </SBlogs>
  );
};

export default Blogs;
