import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const Small = css`
  width: 32px;
  height: 32px;
  background: #ffffff;
  border-radius: 32px;
  padding: 10px;
  transition: background 300ms;
  display: block;
  line-height: 0;

  & svg {
    color: #808080;
    width: 12px;
    height: 12px;
    transition: color 300ms;
  }

  &:hover {
    background: #0a1730;

    svg {
      color: #ffffff;
    }
  }
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SCompFooterSocialBtn = styled.a`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
