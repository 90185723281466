import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const Small = css`
  display: block;
  width: 100%;
  border: 0;
  outline: none;
  border-bottom: 0.5px solid rgba(16, 22, 45, 0.24);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: rgba(32, 32, 32, 0.5);
  padding-bottom: 32px;
  margin-bottom: 58px;

  &::placeholder {
    font-family: Tajawal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: rgba(32, 32, 32, 0.5);
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SContactUsHeroFormInput = styled.input`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
