import axios from "axios";
import React, { useEffect, useState } from "react";
import "./privacy.css";
import Loader from "../../components/Loader";

const Privacy = () => {
  const [content, setContent] = useState("");
  useEffect(() => {
    axios
      .get("https://backend.details.net.sa/wp-json/wp/v2/pages/3")
      .then((res) => setContent(res.data.content.rendered))
      .catch((err) => console.log(err));
  }, []);
  if (!content) return <Loader />;

  return (
    <section className="privay-par">
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </section>
  );
};

export default Privacy;
