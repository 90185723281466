import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const Small = css`
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  padding: 45px 32px;
`;

const Medium = css``;

const Large = css`
  padding: 55px 90px;
`;

const XLarge = css`
  padding: 55px 135px;
`;

const XXLarge = css``;

export const SAdvantagesComponentContainer = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
