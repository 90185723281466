import { SContactUsMap } from "./styles/SContactUsMap";
import { SContactUsMapIcon } from "./styles/SContactUsMapIcon";
import { SContactUsMapPopup } from "./styles/SContactUsMapPopup";
import { SContactUsMapPopupBtn } from "./styles/SContactUsMapPopupBtn";
import { SContactUsMapPopupText } from "./styles/SContactUsMapPopupText";
import { SContactUsMapWrapper } from "./styles/SContactUsMapWrapper";
import image from "../../assets/images/contact-us-map.png";

const ContactUsMap = () => {
  //   <iframe
  //   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.23594771452!2d46.61714207510335!3d24.82160344681169!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2ee58ac5e0d2f9%3A0xa3dc153d0897be45!2sBurj%20Al%20Tamkeen!5e0!3m2!1sen!2seg!4v1698917731195!5m2!1sen!2seg"
  //   width="600"
  //   height="450"
  //   allowfullscreen=""
  //   loading="lazy"
  //   referrerpolicy="no-referrer-when-downgrade"
  // ></iframe>
  return (
    <SContactUsMap className="contact-us-map">
      <SContactUsMapWrapper>
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.40)",
            position: "absolute",
            height: "100%",
            width: "100%",
            top: 0,
            zIndex: "30",
          }}
        />
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3573.568816899055!2d50.061185775151884!3d26.405114581720532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDI0JzE4LjQiTiA1MMKwMDMnNDkuNSJF!5e0!3m2!1sen!2seg!4v1699477902391!5m2!1sen!2seg"
          width="600"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        <SContactUsMapIcon>
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15 2.5C21.2132 2.5 26.25 7.5368 26.25 13.75C26.25 17.5922 24.1551 20.7365 21.948 22.9931C20.7371 24.2311 19.4496 25.2466 18.3521 26.0141L17.8205 26.3771C17.7349 26.4341 17.651 26.4894 17.5689 26.5428L17.0986 26.8424L16.6786 27.0989L16.1589 27.4012C15.4349 27.8101 14.5651 27.8101 13.8411 27.4012L13.3214 27.0989L12.6721 26.698C12.5936 26.6481 12.5133 26.5963 12.4311 26.5428L11.9181 26.2008C10.7656 25.4144 9.36383 24.3343 8.05202 22.9931C5.84487 20.7365 3.75 17.5922 3.75 13.75C3.75 7.5368 8.7868 2.5 15 2.5ZM15 10C12.9289 10 11.25 11.6789 11.25 13.75C11.25 15.8211 12.9289 17.5 15 17.5C17.0711 17.5 18.75 15.8211 18.75 13.75C18.75 11.6789 17.0711 10 15 10Z"
              fill="#0A1730"
            />
          </svg>
        </SContactUsMapIcon>
        <SContactUsMapPopup>
          <SContactUsMapPopupText>
            امبنى جستس مكتب 208أ شارع عمر بن الخطاب، الدمام - المملكة العربية السعودية
          </SContactUsMapPopupText>
          <SContactUsMapPopupBtn href="#">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.9167 22H10.0833V15.5834C10.0833 13.0167 8.15833 11 5.68333 11H3.39167L6.325 14.025L5.04167 15.3084L0 10.0834L5.04167 4.85836L6.325 6.14169L3.39167 9.16669H5.68333C7.425 9.16669 8.98333 9.90002 10.0833 11.0917V11C10.0833 7.42502 12.8333 4.58336 16.3167 4.58336H18.6083L15.675 1.55836L16.9583 0.275024L22 5.50002L16.9583 10.725L15.675 9.44169L18.6083 6.41669H16.3167C13.8417 6.41669 11.9167 8.43336 11.9167 11V22Z"
                fill="#0A1730"
              />
            </svg>
            الإتجاهات
          </SContactUsMapPopupBtn>
        </SContactUsMapPopup>
      </SContactUsMapWrapper>
    </SContactUsMap>
  );
};

export default ContactUsMap;
