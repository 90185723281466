import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "../../../constants/mediaquerys";

const Small = css`
  display: flex;
  flex-direction: column;
`;

const Medium = css``;

const Large = css`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const XLarge = css``;

const XXLarge = css``;

export const SCompArticlesTitleAndBtn = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
