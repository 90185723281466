import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "../../../constants/mediaquerys";
import {SAdvantagesComponentCardIcon} from "./SAdvantagesComponentCardIcon";

const Small = css`
  background: #F9F9F9;
  border-radius: 5px;
  padding: 40px 30px 40px 45px;
  box-shadow: 15px 30px 45px rgba(76, 94, 89, 0);
  transition-property: background, box-shadow;
  transition-duration: 300ms;
  
  &:hover {
    background: #FFFFFF;
    box-shadow: 15px 30px 45px rgba(76, 94, 89, 0.1);
    
    ${SAdvantagesComponentCardIcon} {
      background: #F9F9F9;
    }
  }
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SAdvantagesComponentCard = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
