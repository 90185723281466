import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import { SHero } from "./styles/SHero";
import { SHeroButtons } from "./styles/SHeroButtons";
import { SHeroContainer } from "./styles/SHeroContainer";
import { SHeroContent } from "./styles/SHeroContent";
import { SHeroImage } from "./styles/SHeroImage";
import { SHeroLines } from "./styles/SHeroLines";
import { SHeroNavigationButton } from "./styles/SHeroNavigationButton";
import { SHeroNavigationButtons } from "./styles/SHeroNavigationButtons";
import { SHeroText } from "./styles/SHeroText";
import { SHeroTitle } from "./styles/SHeroTitle";
import HeroImage from "../../assets/images/hero-image.png";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import AOS from "aos";
import "aos/dist/aos.css";
SwiperCore.use([Navigation]);

const Hero = () => {
  const [, setSwiperRef] = React.useState(null);
  const prevBtnRef = React.useRef(null);
  const nextBtnRef = React.useRef(null);
  useEffect(() => {
    AOS.init({
      duration: 3000,
    });
    AOS.refresh();
  }, []);
  return (
    <SHero className="hero">
      <SHeroContainer>
        <SHeroContent>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            watchOverflow
            navigation={{
              prevEl: prevBtnRef.current,
              nextEl: nextBtnRef.current,
            }}
            onSwiper={setSwiperRef}
          >
            <SwiperSlide>
              <SHeroTitle data-aos="fade-left">
                مرحباً بك في تطبيق <span style={{ color: "#0A1730", fontWeight: "800" }}>بيزي</span>, أول تطبيق سعودي
                يتبنى نموذج تقديم الخدمات
              </SHeroTitle>
              <SHeroText data-aos="fade-left">
                أول تطبيق سعودي يتبنى نموذج تقديم الخدمات عند الطلب بين أصحاب المهارات الراغبين بالعمل وطالبي الخدمات من
                أفراد أو شركات .
              </SHeroText>
              <SHeroButtons>
                <Link to="/download-app">
                  <Button btnType="primary" as="span">
                    حمل التطبيق
                  </Button>
                </Link>
                <Link to="/">
                  <Button btnType="secondary" as="span">
                    أعرف المزيد
                  </Button>
                </Link>
              </SHeroButtons>
            </SwiperSlide>
            <SwiperSlide>
              <SHeroTitle>
                اجعل حياتك أكثر سهولة و راحة مع <span style={{ color: "#0A1730" }}>بيزي</span>
              </SHeroTitle>
              <SHeroText>
                الوسيط المثالي بينك وبين صاحب المهارة التي تحتاجها ، اطلب الخدمة التي ترغب بها و سيقوم بيزي بتوصيلك
                مباشرة مع الخبراء المؤهلين لتلبية احتياجاتك.{" "}
              </SHeroText>
              <SHeroButtons>
                <Link to="/download-app">
                  <Button btnType="primary" as="span">
                    حمل التطبيق
                  </Button>
                </Link>
              </SHeroButtons>
            </SwiperSlide>
          </Swiper>
          <SHeroNavigationButtons>
            <SHeroNavigationButton ref={prevBtnRef}>
              <svg width="46" height="8" viewBox="0 0 46 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5L45 4.5V3.5L0 3.5L0 4.5Z"
                  fill="black"
                />
              </svg>
            </SHeroNavigationButton>
            <SHeroNavigationButton ref={nextBtnRef}>
              <svg width="46" height="8" viewBox="0 0 46 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.646446 4.35355C0.451183 4.15829 0.451183 3.84171 0.646446 3.64645L3.82843 0.464466C4.02369 0.269204 4.34027 0.269204 4.53553 0.464466C4.7308 0.659728 4.7308 0.976311 4.53553 1.17157L1.70711 4L4.53553 6.82843C4.7308 7.02369 4.7308 7.34027 4.53553 7.53553C4.34027 7.7308 4.02369 7.7308 3.82843 7.53553L0.646446 4.35355ZM46 4.5L1 4.5V3.5L46 3.5V4.5Z"
                  fill="black"
                />
              </svg>
            </SHeroNavigationButton>
          </SHeroNavigationButtons>
        </SHeroContent>
        <SHeroImage>
          <img src={HeroImage} alt="heroImage" />
        </SHeroImage>
        <SHeroLines>
          <svg width="136" height="495" viewBox="0 0 136 495" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M423.523 282.658L92.9024 315.145" stroke="#0A1730" strokeWidth="4" strokeLinecap="round" />
            <path d="M370.777 335.925L81.8303 363.745" stroke="#0A1730" strokeWidth="4" strokeLinecap="round" />
            <path d="M353.617 242.588L109.12 266.613" stroke="#0A1730" strokeWidth="4" strokeLinecap="round" />
            <path d="M465.319 183.528L58.4019 223.512" stroke="#0A1730" strokeWidth="4" strokeLinecap="round" />
          </svg>
        </SHeroLines>
      </SHeroContainer>
    </SHero>
  );
};

export default Hero;
