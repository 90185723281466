import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "../../../constants/mediaquerys";

const Small = css`
  
  h3, h2 {
    font-weight: 700;
    font-size: 26px;
    line-height: 28px;
    color: #2E1D0D;
    margin-bottom: 28px;
    
    &:not(&:first-of-type) {
      margin-top: 60px;
    }
  }
  
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #8C7E7B;
    margin-bottom: 18px;
  }
  
  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SBlogDetailsContent = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
