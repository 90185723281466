import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "../../../constants/mediaquerys";

const Small = css`
  display: none;
`;

const Medium = css``;

const Large = css`
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  top: 24px;
`;

const XLarge = css``;

const XXLarge = css``;

export const SCompStepsLine = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
