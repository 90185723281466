import React from "react";
import "./style/index.css";
import Button from "../Button";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <section className="about-sec">
      <h2>عن بيزي</h2>
      <p>
        أول تطبيق سعودي يتبنى نموذج تقديم الخدمات عند الطلب بين أصحاب المهارات الراغبين بالعمل وطالبي الخدمات من أفراد
        أو شركات ، حيث يتم طلب الخدمة وتنفيذها من قبل مقدم الخدمة أول تطبيق سعودي يتبنى نموذج تقديم الخدمات عند الطلب
        بين أصحاب المهارات الراغبين بالعمل وطالبي الخدمات من أفراد أو شركات ، حيث يتم طلب الخدمة وتنفيذها من قبل مقدم
        الخدمة أول تطبيق سعودي يتبنى نموذج تقديم الخدمات عند الطلب بين أصحاب المهارات الراغبين بالعمل وطالبي الخدمات من
        أفراد أو شركات ، حيث يتم طلب الخدمة وتنفيذها من قبل مقدم الخدمة{" "}
      </p>
      <Link to="/about-us">
        <Button btnType="primary" as="span">
          عرض المزيد
        </Button>
      </Link>
    </section>
  );
};

export default About;
