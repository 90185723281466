import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const Small = css`
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 37.84%;
  left: 3.88%;
  z-index: 40;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const Medium = css``;

const Large = css`
  width: 62px;
  height: 62px;
  top: 27.84%;
  left: 30.88%;
  display: flex;
  svg {
    width: 30px;
    height: 30px;
  }
`;

const XLarge = css``;

const XXLarge = css``;

export const SContactUsMapIcon = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
