import { useRef, useState } from "react";
import { SCompFaqs } from "./styles/SCompFaqs";
import { SCompFaqsContainer } from "./styles/SCompFaqsContainer";
import { SCompFaqsContent } from "./styles/SCompFaqsContent";
import { SCompFaqsFaq } from "./styles/SCompFaqsFaq";
import { SCompFaqsFaqText } from "./styles/SCompFaqsFaqText";
import { SCompFaqsFaqTextCont } from "./styles/SCompFaqsFaqTextCont";
import { SCompFaqsFaqTitle } from "./styles/SCompFaqsFaqTitle";
import { SCompFaqsImage } from "./styles/SCompFaqsImage";
import { SCompFaqsImageCont } from "./styles/SCompFaqsImageCont";
import { SCompFaqsText } from "./styles/SCompFaqsText";
import { SCompFaqsTitle } from "./styles/SCompFaqsTitle";
import image from "../../assets/images/faqs-image.png";
import { SCompFaqsWrapper } from "./styles/SCompFaqsWrapper";
import "./styles/bigScreenFaq.css";
import { Link } from "react-router-dom";
import { HiLink } from "react-icons/hi";

const data = [
  {
    title: "كيفية التسجيل ؟",
    text: "أول تطبيق سعودي يتبنى نموذج تقديم الخدمات عند الطلب بين أصحاب المهارات الراغبين بالعمل وطالبي الخدمات من أفراد أو شركات ، حيث يتم طلب الخدمة وتنفيذها من قبل مقدم الخدمة ",
  },
  {
    title: "ما الخطوات إذا نسيت كلمة المرور ؟",
    text: "عن طريق تحميل تطبيق بيزي علي (Apple Store أو Google Play) ، ثم تقوم بتسجيل بياناتك الشخصية مثل رقم الجوال والبريد الإلكتروني وبيانات أخري.",
  },
  {
    title: "ما كيف أبلغ عن خدمة سيئة ؟",
    text: "يتم الدفع إلكترونياً عن طريق التطبيق أو نقداً لأصحاب الخدمات مباشرةً ، وتسعير الخدمات يكون بتقييم صاحب المهارة.",
  },
  {
    title: "ما الخطوات إذا نسيت كلمة المرور ؟",
    text: "يفرض تطبيق بيزي رسوم ثابتة قدرها 10 % علي صاحب الطلب ، وذلك علي كل طلب يتم الاتفاق عليه.",
  },
  {
    title: "ما الخطوات إذا نسيت كلمة المرور ؟",
    text: "يستطيع صاحب المهارة سحب أرباحه من خلال التطبيق عندما تصل لقيمة 500 ريال ، وذلك عن طريق تزويدنا برقم حساب بنكي مقبول للتحويل.",
  },
  {
    title: "كيفية التسجيل ؟",
    text: "يقوم تطبيق Busy بطلب توثيق الحساب بتزويدنا بوثائق رسمية تثبت هوية المستخدم ، أو بصورة شخصية أو التصوير مع وثيقة إثبات الشخصية أو عمل مكالمات صوتية أو فيديو.",
  },
  {
    title: "ما كيف أبلغ عن خدمة سيئة ؟",
    text: "لا ، تستطيع الشركات أن تقدم خدماتها بعد تقديم كل الأوراق الرسمية التي تثبت عملهم.",
  },
];

export const Faq = ({ title, text, video, id, bigScreen }) => {
  const [active, setActive] = useState(id === 0);
  const ref = useRef(null);

  return (
    <SCompFaqsFaq className="comp-faqs">
      <SCompFaqsFaqTitle
        isOpened={active}
        onClick={() => {
          setActive(!active);
        }}
        className={`${bigScreen && "q-parent"}`}
      >
        {title}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="14"
          viewBox="0 0 23 14"
          fill="none"
          style={{ marginRight: "34px" }}
        >
          <path
            d="M10.4393 13.0607C11.0251 13.6464 11.9749 13.6464 12.5607 13.0607L22.1066 3.51472C22.6924 2.92893 22.6924 1.97918 22.1066 1.3934C21.5208 0.807612 20.5711 0.807612 19.9853 1.3934L11.5 9.87868L3.01472 1.3934C2.42893 0.807612 1.47918 0.807612 0.893398 1.3934C0.307612 1.97918 0.307612 2.92893 0.893398 3.51472L10.4393 13.0607ZM10 11V12L13 12V11L10 11Z"
            fill="black"
          />
        </svg>
      </SCompFaqsFaqTitle>

      <SCompFaqsFaqTextCont ref={ref} isOpened={active} heightValue={ref?.current?.scrollHeight}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {bigScreen && <iframe src={video} title="Busy" width="auto" height="400px"></iframe>}
          {bigScreen && (
            <Link to={`/faq/${id}`}>
              <HiLink size={30} color="#000" />
            </Link>
          )}
        </div>
        <SCompFaqsFaqText
          className={`${bigScreen && "answer"}`}
          dangerouslySetInnerHTML={{ __html: text }}
        ></SCompFaqsFaqText>
      </SCompFaqsFaqTextCont>
    </SCompFaqsFaq>
  );
};
const CompFaqs = () => {
  return (
    <SCompFaqs>
      <SCompFaqsContainer>
        <div className="faq-header">
          <div>
            <SCompFaqsTitle>الأسئلة الشائعة</SCompFaqsTitle>
            <SCompFaqsText>الأسئلة المتكررة من العملاء</SCompFaqsText>
          </div>
          <Link to={"/faq"}>عرض المزيد</Link>
        </div>
        <SCompFaqsContent>
          <SCompFaqsImageCont>
            <SCompFaqsImage>
              <img src={image} alt="" />
            </SCompFaqsImage>
          </SCompFaqsImageCont>
          <SCompFaqsWrapper>
            {data.map((el, i) => (
              <Faq {...el} index={i} key={`${el.title}-${i}`} />
            ))}
          </SCompFaqsWrapper>
        </SCompFaqsContent>
      </SCompFaqsContainer>
    </SCompFaqs>
  );
};

export default CompFaqs;
